<template>
  <v-container class="window" style="margin-left:11%; margin-right:11%; width: 78%;">
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" :opacity="opacity" indeterminate color="white"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title>
        Inquiry Refund Validator
      </v-card-title>
      <v-divider></v-divider>
      <v-row class="mx-1 mt-1">
        <v-col cols="3" sm="3" md="3" lg="3">
          <v-text-field v-model="cariData.nomor_bupot" label="Nomor Refund" outlined dense></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <v-combobox v-model="cariData.user_name" :items="listUser" item-text="username" label="Supplier"
            placeholder="Username" :menu-props="{ maxHeight: 500 }" outlined dense>
          </v-combobox>
          <!-- <v-text-field
                  v-model="cariData.user_name"
                  label="Nama User"
                ></v-text-field> -->
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <!-- <v-text-field v-model="cariData.tanggal_awal" single-line label="Dari Tanggal" readonly outlined dense>
            <template v-slot:append-outer>
              <date-picker v-model="cariData.tanggal_awal" />
            </template>
</v-text-field> -->
          <v-menu v-model="menuSearchTanggalAwal" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="cariData.tanggal_awal" label="Start Date" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on" outlined dense></v-text-field>
            </template>
            <v-date-picker v-model="cariData.tanggal_awal" @input="menuSearchTanggalAwal = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <!-- <v-text-field v-model="cariData.tanggal_akhir" single-line label=" Sampai Tanggal" readonly outlined dense>
            <template v-slot:append-outer>
              <date-picker v-model="cariData.tanggal_akhir" />
            </template>
          </v-text-field> -->
          <v-menu v-model="menuSearchTanggalAkhir" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="cariData.tanggal_akhir" label="End Date" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on" outlined dense></v-text-field>
            </template>
            <v-date-picker v-model="cariData.tanggal_akhir" @input="menuSearchTanggalAkhir = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <!-- <v-divider></v-divider> -->
      <v-row class="mx-1 mb-1">
        <v-col cols="6" sm="6" md="6" lg="6" align="start">
          <v-btn color="success" dark @click="searchData">
            Cari Data
            <v-icon dark right>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="error" dark class="ml-2" @click="resetData">
            Reset Data
            <v-icon dark right>
              mdi-minus-circle
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="9" sm="9" md="9" lg="9">
          <v-btn color="success" dark class="mb-4 ml-4 mt-4" @click="validasi" x-large>
            Validasi
          </v-btn>
          <v-btn color="error" dark class="mb-4 ml-2 mt-4" @click="tolak" x-large>
            Tolak
          </v-btn>
          <v-btn color="primary" dark class="mb-4 ml-2 mt-4" @click="edit" x-large>
            Edit
          </v-btn>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <v-select v-model="selectedStatus" :items="itemStatus" label="Status Refund" item-text="name"
            item-value="value" class="mb-4 mt-4 mr-4" placeholder="Status Refund" outlined dense @change="changeType">
          </v-select>
          <!-- <v-text-field
                                      v-model="cariData.user_name"
                                      label="Nama User"
                                    ></v-text-field> -->
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-dialog v-model="dialog" max-width="600px" scrollable>
        <v-card>
          <v-toolbar color="success" dark>Tolakan Bupot</v-toolbar>

          <!-- <p>Alasan Penolakan Bukti Potong</p>
                  <p>No.Bupot {{this.tolakBupot[0].bupot_number}}}</p> -->
          <v-card-text class="mt-2 py-0">Alasan Penolakan Bukti Potong dengan</v-card-text>
          <v-card-text class="my-0 py-0">Customer: [{{ this.tolakBupot[0].customer_id }}]
            [{{ this.tolakBupot[0].customer_name }}]</v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea solo name="input-7-4" label="Alasan Penolakan" hint="Hint text"
                  v-model="alasanTolak.alasan_tolak"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <!-- <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="editedItem.menu_name"
                      label="Menu Name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="editedItem.menu_desc"
                      label="Menu Desc"
                    ></v-text-field>
                  </v-col>
                                    <v-col
                    cols="12"
                  >
                    <v-autocomplete
                      ref="role_id"
                      v-model="editedItem.role_id"
                      :items="role_id"
                      item-text="role_name"
                      item-value="id"
                      label="Role ID "
                      placeholder="Select..."
                      required
                    ></v-autocomplete>
                  </v-col>
                                    <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="editedItem.seq"
                      label="Seq"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                  <p>Active Flag</p>
                        <v-radio-group
                        v-model="editedItem.active_flag"
                        row
                        >
                        <v-radio
                          label="Y"
                          value="Y"
                        ></v-radio>
                        <v-radio
                          label="N"
                          value="N"
                        ></v-radio>
                    </v-radio-group>
                  </v-col>
                                    <v-col
                    cols="12"
                  >
                  <p>Is Detail</p>
                        <v-radio-group
                        v-model="editedItem.is_detail"
                        row
                        >
                        <v-radio
                          label="Y"
                          value="Y"
                        ></v-radio>
                        <v-radio
                          label="N"
                          value="N"
                        ></v-radio>
                    </v-radio-group>
                  </v-col> -->

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogClose">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitTolak">
              Tolak
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog2" max-width="600px" scrollable>
        <v-card>
          <v-toolbar color="primary" dark>Edit Bupot</v-toolbar>

          <!-- <p>Alasan Penolakan Bukti Potong</p>
                  <p>No.Bupot {{this.tolakBupot[0].bupot_number}}}</p> -->
          <!-- <v-card-text class="mt-2 py-0">Alasan Edit Bukti Potong dengan</v-card-text> -->
          <!-- <v-card-text class="my-0 py-0">Customer: [{{ this.tolakBupot[0].customer_id }}]
            [{{ this.tolakBupot[0].customer_name }}]</v-card-text> -->
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-switch v-model="switch1" label="Input Alasan" @click="triggerSwitch"></v-switch>
                <v-textarea v-model="alasanEdit.alasan_edit_input" label="Input Alasan edit" auto-grow outlined rows="1"
                  row-height="15" :disabled="disableIsiAlasan"></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-combobox v-model="alasanEdit.alasan_edit" :items="listAlasan" item-text="ALASAN" item-value="ALASAN"
                  :disabled="disabledPilihAlasan" label="Pilih Alasan Edit" placeholder="Pilih Alasan Edit" outlined>
                </v-combobox>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog2Close">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitEdit">
              Lanjut
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog4" max-width="600px" scrollable>
        <v-card>
          <v-toolbar color="primary" dark>Tolak Bupot</v-toolbar>

          <!-- <p>Alasan Penolakan Bukti Potong</p>
                  <p>No.Bupot {{this.tolakBupot[0].bupot_number}}}</p> -->
          <!-- <v-card-text class="mt-2 py-0">Alasan Edit Bukti Potong dengan</v-card-text> -->
          <!-- <v-card-text class="my-0 py-0">Customer: [{{ this.tolakBupot[0].customer_id }}]
            [{{ this.tolakBupot[0].customer_name }}]</v-card-text> -->
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-switch v-model="switch1" label="Input Alasan" @click="triggerSwitch"></v-switch>
                <v-textarea v-model="alasanTolak.alasan_tolak_input" label="Input Alasan Tolak" auto-grow outlined
                  rows="1" row-height="15" :disabled="disableIsiAlasan"></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-combobox v-model="alasanTolak.alasan_tolak" :items="listAlasan" item-text="ALASAN"
                  item-value="ALASAN" :disabled="disabledPilihAlasan" label="Pilih Alasan Tolak"
                  placeholder="Pilih Alasan Edit" outlined>
                </v-combobox>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog4Close">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitTolak">
              Lanjut
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog3" max-width="600px" scrollable>
        <v-card>
          <v-toolbar color="success" dark>Validasi Bupot</v-toolbar>

          <!-- <p>Alasan Penolakan Bukti Potong</p>
                  <p>No.Bupot {{this.tolakBupot[0].bupot_number}}}</p> -->
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="tanggal_pembayaran" single-line label="Tanggal Pembayaran" readonly>
                  <template v-slot:append-outer>
                    <date-picker v-model="tanggal_pembayaran" />
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog3Close">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitValidasi">
              Validasi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-divider></v-divider>
      <img id='barcode3' style="display:none;" />
      <v-data-table v-model="selected" :headers="headers" :items="inquiryBupot" item-key="refund_id" class="elevation-1"
        show-select :loading="loading" loading-text="Fetching Data...">
        <template v-slot:[`item.status`]="{ item }">
          <div class="font-weight-bold">
            {{ formatValidasi(item.status) }}
          </div>
        </template>
        <template v-slot:[`item.refund_number`]="{ item }">
          <!-- <router-link :to="{name: 'DetailBupot', params: { id: value }}"> -->
          <a @click="showPdf(item)">
            {{ item.refund_number }}
          </a>
          <!-- </router-link> -->
        </template>
        <template v-slot:[`item.dpp_amount`]="{ item }">
          {{ formatCurrency(item.dpp_amount) }}
        </template>
        <template v-slot:[`item.pph_amount`]="{ item }">
          {{ formatCurrency(item.pph_amount) }}
        </template>
        <template v-slot:[`item.bupot_date`]="{ item }">
          {{ formatDate(item.bupot_date) }}
        </template>
        <template v-slot:[`item.status_refund`]="{ item }">
          <v-chip :color="getColor(item.status_refund)" dark>
            {{ item.status_refund }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <!-- <h5>Selected: {{selected}}</h5>
  <h5>Selected: {{selected2}}</h5> -->

  </v-container>
</template>

<script>
import DatePicker from "../../../components/DatePicker.vue";
import axios from 'axios'
import { mapGetters } from 'vuex'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import JsBarcode from 'jsbarcode';
export default {
  data() {
    return {
      custid2: [],
      idTolak: [],
      switch1: true,
      alasanTolak: {},
      alasanEdit: {},
      tolakBupot: [0],
      editedItem: [],
      idValidasi: [],
      data: [],
      data2: [],
      data3: [],
      cariData: [],
      menuSearchTanggalAwal: false,
      menuSearchTanggalAkhir: false,
      loading: false,
      search: '',
      dialog: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      singleSelect: false,
      overlay: false,
      opacity: 0.5,
      selected: [],
      disabledPilihAlasan: true,
      disableIsiAlasan: false,
      selectedIndex: -1,
      selectedItem: {},
      dokumenKwtarray: [],
      dokumenKwtarray2: [],
      dokumenKwtarray3: [],
      selectedCustomers: [],
      selectedEmail: [],
      listUser: [],
      listAlasan: [],
      inquiryBupot: [],
      tanggal_pembayaran: '',
      supplier_site_name: '',
      varUserId: '',
      selectedStatus: 'Input Refund',
      itemStatus: [
        { name: 'Input Refund', value: 'Input Refund' },
        { name: 'Input Bupot', value: 'Input Bupot' },
      ],
      headers: [
        { text: 'Status', value: 'status' },
        { text: 'Nomor Refund/BuPot', value: 'refund_number' },
        { text: 'Tanggal Refund/BuPot', value: 'refund_date' },
        { text: 'DPP', value: 'dpp_amount' },
        { text: 'PPh', value: 'pph_amount' },
        { text: 'Jumlah Kwitansi', value: 'JumlahKwitansi' },
        { text: 'Status Refund', value: 'status_refund' },
        { text: 'Status PPh', value: 'status_pph' },
        { text: 'Flag Edit', value: 'flag_edit' },
      ]
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Role' : 'Edit Role'
    },
    ...mapGetters({
      user: 'user',
    })
  },
  mounted() {
    axios({
      method: 'get',
      url: 'getDatauser',
    })
      .then(response => {
        //  console.log(response.data.data)
        this.listUser = response.data.data
        // window.location.reload();
      })
      .catch(error => {
        console.log(error.response)
      })
    axios({
      method: 'get',
      url: 'getalasan',
    })
      .then(response => {
        //  console.log(response.data.data)
        this.listAlasan = response.data.data
        // window.location.reload();
      })
      .catch(error => {
        console.log(error.response)
      })
  },
  created() {
    this.loading = true;
    // let uri = `trxpagevalidator`;
    axios({
      method: 'post',
      url: 'trxpagevalidator',
      data: {
        status_refund: this.selectedStatus
      }
    })
      .then(response => {
        this.inquiryBupot = response.data.data;
        this.loading = false;
      })
      .catch(error => {
        console.log(error.response)
      })
    // axios.get(uri).then(response => {
    //   this.inquiryBupot = response.data.data;
    //   this.loading = false;
    // });
  },
  methods: {
    getColor(item) {
      if (item === 'Input Refund') {
        return 'primary';
      } else {
        return 'green';
      }
    },
    showBupotid(a) {
      this.selectedIndex = this.inquiryBupot.indexOf(a);
      this.selectedItem = Object.assign({}, a);
      // alert(a);
    },
    searchData() {
      if (typeof (this.cariData.tanggal_awal) !== 'undefined' && typeof (this.cariData.tanggal_akhir) !== 'undefined') {
        this.loading = true;
        var d1 = new Date(this.cariData.tanggal_awal).getTime()
        var d2 = new Date(this.cariData.tanggal_akhir).getTime()
        if (d1 <= d2) {
          axios({
            method: 'post',
            url: 'searchdatavalidator',
            data: {
              nomor_bupot: this.cariData.nomor_bupot,
              tanggal_awal: this.cariData.tanggal_awal,
              tanggal_akhir: this.cariData.tanggal_akhir,
              user_name: this.cariData.user_name,
              status_refund: this.selectedStatus
            },
          })
            .then(response => {
              this.inquiryBupot = response.data.data;
              this.loading = false;
              // window.location.reload();
            })
            .catch(error => {
              console.log(error.response)

            })
        } else {
          this.$swal.fire(
            'Peringatan!',
            'Tanggal Akhir Tidak Boleh Lebih Kecil Dari Tanggal Awal',
            'warning'
          )
        }
      } else {
        this.$swal.fire(
          'Peringatan!',
          'Harap Isi Semua Tanggal!',
          'warning'
        )
      }
    },
    resetData() {
      this.loading = true;
      // let uri = `trxpagevalidator`;
      axios({
        method: 'post',
        url: 'trxpagevalidator',
        data: {
          status_refund: this.selectedStatus
        }
      })
        .then(response => {
          this.inquiryBupot = response.data.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error.response)
        })
      this.cariData = [];
      this.selected = [];
    },
    formatValidasi(value) {
      if (value == "D") {
        return "Draft"
      } else if (value == "S") {
        return "Submit"
      } else if (value == "V") {
        return "Valid"
      } else if (value == "R") {
        return "Reject"
      } else if (value == "C") {
        return "Cancel"
      } else if (value == "E") {
        return "Edit"
      }
    },
    validasi() {
      // console.log(this.selected);
      this.tanggal_pembayaran = '';
      if (this.selected.length == 0) {
        this.$swal.fire(
          'Peringatan!',
          'Silahkan Pilih Bukti Potong yang mau divalidasi!',
          'warning'
        )
      } else {
        // alert(this.selectedStatus);
        if (this.selectedStatus === 'Input Refund') {
          this.idValidasi.splice(0)
          this.data = this.selected
          this.data.forEach(item => {
            this.idValidasi.push(item.refund_id)
          })
          this.dialog3 = true;
        } else {
          this.idValidasi.splice(0)
          this.data = this.selected
          this.data.forEach(item => {
            this.idValidasi.push(item.refund_id)
          })
          this.validasiSubmit();
          // alert("INI MILIH INPUT BUPOT");
        }
        // this.validasiSubmit();            
      }
    },
    validasiSubmit() {
      // console.log(this.selected);
      if (this.tanggal_pembayaran === '' && this.selectedStatus === 'Input Refund') {
        this.$swal.fire(
          'Peringatan!',
          'Harap Mengisi Tanggal Pembayaran!',
          'warning'
        )
      } else {
        this.$swal.fire({
          title: 'Apakah anda yakin ingin Validasi Bukti Potong ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Validasi',
          cancelButtonText: 'Batal'
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: 'post',
              url: 'validasidata',
              data: {
                id_validasi: this.idValidasi,
                tanggal_pembayaran: this.tanggal_pembayaran,
                data: this.selected
              },
            })
              .then(() => {
                axios({
                  method: 'post',
                  url: 'trxpagevalidator',
                  data: {
                    status_refund: this.selectedStatus
                  }
                })
                  .then(response => {
                    this.inquiryBupot = response.data.data;
                    this.loading = false;
                  })
                  .catch(error => {
                    console.log(error.response)
                  })
                this.dialog3Close()
                this.$swal.fire(
                  'Sukses!',
                  'Bukti Potong berhasil di Validasi!',
                  'success'
                )
                this.idTolak.splice(0);
                this.selected = [];
              })
              .catch(error => {
                console.log(error.response)
              })
          }
        })
      }
    },
    tolak() {
      this.alasanTolak = [];
      if (this.selected.length == 0) {
        this.$swal.fire(
          'Peringatan!',
          'Silahkan Pilih Bukti Potong yang mau ditolak!',
          'warning'
        )
      } else {
        this.tolakBupot = this.selected;
        // bukan
        this.submitTolak();
        // diskon 04
        // this.dialog4 = true;
      }
    },
    edit() {
      this.alasanEdit = [];
      if (this.selected.length == 0) {
        this.$swal.fire(
          'Peringatan!',
          'Silahkan Pilih Bukti Potong yang mau diedit!',
          'warning'
        )
      } else {
        this.tolakBupot = this.selected;
        this.idTolak.splice(0)
        this.data2 = this.selected
        this.selected.forEach(item => {
          this.idTolak.push(item.refund_id)
        })
        this.dialog2 = true;

      }
    },
    submitTolak() {
      this.idTolak.splice(0)
      this.data2 = this.selected
      this.data2.forEach(item => {
        this.idTolak.push(item.refund_id)
      })
      this.validasiTolak();
    },
    submitEdit() {
      this.validasiEdit();
    },
    submitValidasi() {
      this.validasiSubmit();
    },
    validasiTolak() {
      var reason = '';
      // diskon 04
      // if (this.switch1 === true) {
      //   // alert("MASOK");
      //   reason = this.alasanTolak.alasan_tolak_input;
      // } else {
      //   // alert("TIDAK MASOK");
      //   reason = this.alasanTolak.alasan_tolak.ALASAN;
      //   // alert(this.alasanEdit.alasan_edit);
      //   // console.log(this.alasanEdit.alasan_edit.ALASAN);
      // }
      // ======
      axios({
        method: 'post',
        url: 'getSelectedCustomerId',
        data: {
          id_tolak: this.idTolak
        },
      })
        .then((response) => {
          this.selectedCustomers = response.data.data;
          axios({
            method: 'post',
            url: 'getCustomerEmail',
            data: {
              id_user: this.selectedCustomers
            },
          })
            .then((response) => {
              this.selectedEmail = response.data.data;
              // console.log(this.selectedEmail);
            })
            .catch(error => {
              console.log(error.response)
            })

        })
        .catch(error => {
          console.log(error.response)
        })
      this.$swal.fire({
        title: 'Apakah anda yakin ingin Menolak Bukti Potong ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Tolak',
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'post',
            url: 'tolakdata',
            data: {
              id_tolak: this.idTolak,
              reason: reason,
              email: this.selectedEmail,
              data: this.selected
            },
          })
            .then(() => {
              axios({
                method: 'post',
                url: 'trxpagevalidator',
                data: {
                  status_refund: this.selectedStatus
                }
              })
                .then(response => {
                  this.inquiryBupot = response.data.data;
                  this.loading = false;
                  this.dialog4Close();
                })
                .catch(error => {
                  console.log(error.response)
                })
              this.dialogClose()
              this.$swal.fire(
                'Sukses!',
                'Bukti Potong berhasil di tolak!',
                'success'
              )
              this.idTolak.splice(0);
              this.selected = [];
            })
            .catch(error => {
              console.log(error.response)
            })
        }
      })
    },
    validasiEdit() {
      // axios({
      //   method: 'post',
      //   url: 'getSelectedCustomerId',
      //   data: {
      //     id_tolak: this.idTolak
      //   },
      // })
      //   .then((response) => {
      //     this.selectedCustomers = response.data.data;
      //     axios({
      //       method: 'post',
      //       url: 'getCustomerEmail',
      //       data: {
      //         id_customers: this.selectedCustomers
      //       },
      //     })
      //       .then((response) => {
      //         this.selectedEmail = response.data.data;
      //         console.log(this.selectedEmail);
      //       })
      //       .catch(error => {
      //         console.log(error.response)
      //       })

      //   })
      //   .catch(error => {
      //     console.log(error.response)
      //   })
      // alert(this.alasanEdit.alasan_edit);
      var reason = '';
      // alert(this.switch1);
      if (this.switch1 === true) {
        // alert("MASOK");
        reason = this.alasanEdit.alasan_edit_input;
      } else {
        // alert("TIDAK MASOK");
        reason = this.alasanEdit.alasan_edit.ALASAN;
        // alert(this.alasanEdit.alasan_edit);
        // console.log(this.alasanEdit.alasan_edit.ALASAN);
      }
      this.$swal.fire({
        title: 'Apakah anda yakin ingin Mengedit Bukti Potong ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Edit',
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'post',
            url: 'editdata',
            data: {
              id_tolak: this.idTolak,
              reason: reason,
              email: this.selectedEmail,
              data: this.selected
            },
          })
            .then((response) => {
              // console.log(response.data.message);
              if (response.data.message === 'S') {
                this.loading = true;
                // let uri = `trxpagevalidator`;
                axios({
                  method: 'post',
                  url: 'trxpagevalidator',
                  data: {
                    status_refund: this.selectedStatus
                  }
                })
                  .then(response => {
                    this.inquiryBupot = response.data.data;
                    this.loading = false;
                  })
                  .catch(error => {
                    console.log(error.response)
                  })
                this.dialog2Close()
                this.$swal.fire(
                  'Sukses!',
                  'Bukti Potong berhasil di edit!',
                  'success'
                )
                this.idTolak.splice(0);
                this.selected = [];
              } else {
                this.loading = true;
                // let uri = `trxpagevalidator`;
                axios({
                  method: 'post',
                  url: 'trxpagevalidator',
                  data: {
                    status_refund: this.selectedStatus
                  }
                })
                  .then(response => {
                    this.inquiryBupot = response.data.data;
                    this.loading = false;
                  })
                  .catch(error => {
                    console.log(error.response)
                  })
                this.dialog2Close()
                this.$swal.fire(
                  'Peringatan!',
                  'Bukti Potong tidak bisa di edit 2 kali!',
                  'warning'
                )
                this.idTolak.splice(0);
                this.selected = [];
              }
            })
            .catch(error => {
              console.log(error.response)
            })
        }
      })
    },
    dialogClose() {
      this.dialog = false;
    },
    dialog2Close() {
      this.dialog2 = false;
    },
    dialog3Close() {
      this.dialog3 = false;
    },
    dialog4Close() {
      this.dialog4 = false;
    },
    changeType() {
      this.loading = true;
      // let uri = `trxpagevalidator`;
      axios({
        method: 'post',
        url: 'trxpagevalidator',
        data: {
          status_refund: this.selectedStatus
        }
      })
        .then(response => {
          this.inquiryBupot = response.data.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error.response)
        })
      this.cariData = [];
      this.selected = [];
    },

    showPdf(item) {
      /* eslint-disable */
      this.overlay = true;
      var today = new Date();
      const formattedDate = today.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      }).replace(/ /g, '-');
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      var hour = today.getHours();
      today = formattedDate;
      var currentHour = hour + ':' + minutes + ':' + seconds;
      JsBarcode("#barcode3", item.refund_number);
      this.dokumenKwtarray3.splice(0);
      var sum = 0;
      var sumb = 0;
      var sumc = 0;
      var sumd = 0;
      var i = 1;
      var sumTotalDppRefund = 0;
      var sumTotalPPhRefund = 0;
      let uri4 = `dtltrxpage/${item.refund_id}`;
      axios.get(uri4).then(response => {
        this.dokumenBupot = response.data.data;
        this.custid2 = this.dokumenBupot;
        axios({
          method: 'post',
          url: 'getUserIdbyCustId',
          data: {
            customer_id: this.custid2
          },
        })
          .then(response => {
            // window.location.reload();
            this.varUserId = response.data.data;
            axios({
              method: 'post',
              url: 'getSuppSiteNameByUserId',
              data: {
                user_id: this.varUserId
              },
            })
              .then((response) => {
                this.supplier_site_name = response.data.data;
                //  alert(response.data.data);
                this.bank = response.data.data;
                let uri5 = `dtlKwtpage/${item.refund_id}/${this.custid2}`;
                axios.get(uri5).then(response => {
                  this.dokumenKwt = response.data.data;
                  let uri = `getcustnumberandname/${this.custid2}`;
                  axios.get(uri).then(response => {
                    this.customer = response.data.data;
                    let uri2 = `getkwtarray/${item.refund_id}/${this.custid2}`;
                    axios.get(uri2).then(response => {
                      this.dokumenKwtarray = response.data.data;
                      // console.log(this.dokumenKwtarray);
                      try {
                        this.dokumenKwtarray2 = this.dokumenKwtarray.map(this.getKwtValue)
                      }
                      catch (err) {
                        console.log(err);
                      }
                      this.data = this.dokumenKwtarray
                      this.data.forEach(item => {
                        this.dokumenKwtarray3.push({
                          bupot_number: item.bupot_number,
                          bupot_date: item.bupot_date,
                          dpp_amount_bupot: this.formatCurrency2(item.dpp_amount_bupot),
                          pph_amount_bupot: this.formatCurrency2(item.pph_amount_bupot),
                          kwt_id: item.kwt_id,
                          kwt_number: item.kwt_number,
                          kwt_date: item.kwt_date,
                          dpp_amount: this.formatCurrency2(item.dpp_amount),
                          ppn_amount: this.formatCurrency2(item.ppn_amount),
                          pph_amount: this.formatCurrency2(item.pph_amount2),
                          tarif: item.tarif
                        })
                      })
                      // console.log(this.dokumenKwtarray3);
                      this.dokumenKwtarray.forEach(item => {
                        sum = sum + parseInt(item.dpp_amount);
                      })
                      this.dokumenKwtarray.forEach(item => {
                        sumb = sumb + parseInt(item.ppn_amount);
                      })
                      this.dokumenKwtarray.forEach(item => {
                        sumc = sumc + parseInt(item.pph_amount2);
                      })
                      sum = this.formatCurrency2(sum);
                      sumb = this.formatCurrency2(sumb);
                      sumc = this.formatCurrency2(sumc);
                      sumTotalDppRefund = this.formatCurrency2(item.dpp_amount);
                      sumTotalPPhRefund = this.formatCurrency2(item.pph_amount);
                      try {
                        this.dokumenKwtarray3 = this.dokumenKwtarray3.map(this.getKwtValue)
                      }
                      catch (err) {
                        console.log(err);
                      }
                      var raw = this.dokumenKwtarray3
                      // console.log(raw);
                      var body = []
                      var bupotIdonly = []
                      var newIdbupotonly = []
                      var index = 1;
                      var count = 0;
                      var counts = [];
                      var indikator = 1;
                      var activeBupot = "";
                      raw.forEach(item => [
                        bupotIdonly.push({
                          bupotNumber: item[0]
                        })
                      ])
                      newIdbupotonly = bupotIdonly.map(this.getBupotNumber);
                      newIdbupotonly.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
                      var j = 0;
                      for (i = 0; i < raw.length; i++) {
                        var row = []
                        var nextRow = []
                        for (var key in raw[i]) {
                          row.push(raw[i][key])
                        }
                        // console.log("sebelum");
                        console.log(row);
                        var no_bupot = row[0];
                        var tanggal_bupot = row[1];
                        var total_dpp_bupot = row[6];
                        var total_pph_bupot = row[8];
                        var no_kwitansi = row[4];
                        var tgl_kwitansi = row[5];
                        var tarif = row[9];
                        row.shift();
                        row.shift();
                        row.shift();
                        row.shift();
                        row.shift();
                        // console.log(row);
                        row.unshift({
                          content: tgl_kwitansi,
                          styles: { valign: 'middle', halign: 'center' },
                        })
                        row.unshift({
                          content: no_kwitansi,
                          styles: { valign: 'middle', halign: 'center' },
                        })
                        row.unshift({
                          content: index,
                          styles: { valign: 'middle', halign: 'center' },
                        })
                        console.log(row);
                        row.push({
                          content: no_bupot,
                          styles: { valign: 'middle', halign: 'center' },
                        })
                        row.push({
                          content: tarif,
                          styles: { valign: 'middle', halign: 'center' },
                        })
                        row.push({
                          content: total_dpp_bupot,
                          styles: { valign: 'middle', halign: 'right' },
                        })
                        row.push({
                          content: total_pph_bupot,
                          styles: { valign: 'middle', halign: 'right' },
                        })
                        row.push({
                          content: tanggal_bupot,
                          styles: { valign: 'middle', halign: 'center' },
                        })
                        console.log(row);
                        row.splice(3, 5);
                        console.log(row);
                        index = index + 1;
                        body.push(row);
                        // console.log("body");
                        // console.log(body);
                      }
                      // for (i = 0; i < raw.length; i++) {
                      //   console.log("data ke",i)
                      //   var row = []
                      //   var nextRow = []
                      //   for (var key in raw[i]) {
                      //     row.push(raw[i][key])
                      //   }
                      //   for (var key2 in raw[i+1]){
                      //     nextRow.push(raw[i+1][key2])
                      //   }
                      //   if(activeBupot != row[0]) {
                      //     indikator = 1;
                      //   } 
                      //   if (indikator === 1){
                      //     activeBupot = row[0];
                      //     indikator = 0;
                      //     count = counts[activeBupot];
                      //     j=0;
                      //   }
                      //   if (j % count === 0){
                      //     var no_bupot = row[0];
                      //     var tanggal_bupot = row[1];
                      //     var total_dpp_bupot = row[2];
                      //     var total_pph_bupot = row[3];              
                      //     row.shift();
                      //     row.shift();
                      //     row.shift();
                      //     row.shift();
                      //     row.unshift({
                      //       rowSpan: count,
                      //       content: total_pph_bupot,
                      //       styles: { valign: 'middle', halign: 'center' },
                      //     })
                      //     row.unshift({
                      //       rowSpan: count,
                      //       content: total_dpp_bupot,
                      //       styles: { valign: 'middle', halign: 'center' },
                      //     })
                      //     row.unshift({
                      //       rowSpan: count,
                      //       content: tanggal_bupot,
                      //       styles: { valign: 'middle', halign: 'center' },
                      //     })
                      //     row.unshift({
                      //       rowSpan: count,
                      //       content: no_bupot,
                      //       styles: { valign: 'middle', halign: 'center' },
                      //     })
                      //     row.unshift({
                      //       rowSpan: count,
                      //       content: index,
                      //       styles: { valign: 'middle', halign: 'center' },
                      //     })
                      //     j=j+1;
                      //     index = index + 1;
                      //   }
                      //   else{
                      //     row.shift();
                      //     row.shift();
                      //     row.shift();
                      //     row.shift();
                      //     j=j+1
                      //   }
                      //   body.push(row);
                      //   console.log(body)
                      // }
                      const img = document.querySelector('img#barcode3');
                      const logo = require('@/assets/indogrosir.png')
                      var imgLogo = new Image()
                      imgLogo.src = logo
                      // var img3 = new Image();
                      // img3.url = '../../../assets/indogrosir.png';
                      var cust_name = this.customer.map(({ customer_name }) => customer_name)
                      var cust_number = this.customer.map(({ customer_number }) => customer_number)
                      var header = cust_name;
                      var tglKembali = this.formatDate2(item.bupot_giro_date);
                      if (item.bupot_giro_date == null) {
                        tglKembali = null;
                      }
                      var validationDate = this.formatDate2(item.validation_date);
                      if (item.validation_date == null) {
                        validationDate = null;
                      }
                      // doc.text(header, 13, 5, { baseline: 'middle' });
                      var doc = new jsPDF();
                      // doc.addImage("../../../assets/indogrosir.png", 'JPEG', 55, 25,100,25);
                      doc.setFont("helvetica", "bold");
                      doc.getFontList();
                      if (item.status === 'T' || item.status === 'V') {
                        // doc.text("TANDA TERIMA BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        if (item.status_refund === 'Input Refund') {
                          doc.text("TANDA TERIMA REFUND BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        } else {
                          doc.text("TANDA TERIMA PENYERAHAN BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        }
                      } else {
                        if (item.status_refund === 'Input Refund') {
                          doc.text("TANDA TERIMA REFUND BUKTI POTONG SEMENTARA", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        } else {
                          doc.text("TANDA TERIMA PENYERAHAN BUKTI POTONG SEMENTARA", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        }
                      }
                      doc.setFont("helvetica", "normal");
                      doc.setFontSize(10);
                      doc.addImage(img.src, 'JPEG', 80, 40, 50, 25);
                      doc.addImage(imgLogo, 'PNG', 5, 5, 50, 20)
                      doc.text("Nomor TT : " + item.refund_number + ' / ' + item.status_pph, 80, 38, { baseline: 'middle', align: 'left', lineHeightFactor: '0.5' });
                      doc.setFontSize(7);
                      doc.text("Supplier", 140, 7, { align: 'left', lineHeightFactor: '0.5' });
                      doc.setFontSize(7);
                      doc.text(header, 140, 12, { align: 'left', lineHeightFactor: '0.5' });
                      doc.text(this.supplier_site_name, 140, 17, { align: 'left', lineHeightFactor: '0.5' });
                      doc.setFontSize(9);
                      doc.autoTable({
                        columnStyles: {
                          0: { cellWidth: 10 },
                          1: { cellWidth: 34 },
                          2: { cellWidth: 25 },
                          3: { cellWidth: 34 },
                          4: { cellWidth: 16 },
                          5: { cellWidth: 28 },
                          6: { cellWidth: 28 },
                          7: { cellWidth: 25 }
                        },
                        headStyles: {
                          lineWidth: 0.5,
                          lineColor: [0, 0, 0],
                          fillColor: [255, 255, 255],
                          textColor: 'black'
                        },
                        theme: 'grid',
                        head: [[
                          { content: 'No ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: 'Nomor Invoice / Kwitansi ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: 'Tgl Kwitansi ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: 'Nomor Bupot ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: 'Tarif PPH(%) ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: 'Nilai (Rp.) ', colSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: 'Tgl BuPot ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                        ], [
                          { content: 'DPP', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: 'PPH', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                        ]],
                        bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
                        startY: 70,
                        margin: { top: 5, left: 5, right: 2 },
                        body: body,
                        footStyles: {
                          lineWidth: 0.5,
                          lineColor: [0, 0, 0],
                          fillColor: [255, 255, 255],
                          textColor: 'black'
                        },
                        rowPageBreak: 'auto'
                      })
                      let finalY = doc.lastAutoTable.finalY;
                      doc.setFont("helvetica", "bold");
                      doc.autoTable({
                        columnStyles: {
                          0: { cellWidth: 119 },
                          1: { cellWidth: 28 },
                          2: { cellWidth: 28 },
                          3: { cellWidth: 25 },
                        },
                        bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
                        body: [[
                          { content: 'TOTAL', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                          { content: sum, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
                          { content: sumc, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
                          { content: '', colSpan: 1, styles: { halign: 'right', fillColor: [224, 224, 235], textColor: 'black', fontSize: 9 } }
                        ]],
                        margin: { top: 5, left: 5, right: 2 },
                        startY: finalY,
                        lineColor: [0, 0, 0]
                      })
                      finalY = doc.lastAutoTable.finalY;
                      finalY = finalY + 10; // The y position on the page
                      if (item.status === 'T' || item.status === 'V') {
                        doc.setFont("helvetica", "bold");
                        doc.text(5, finalY, "Catatan");
                        finalY = finalY + 5;
                        doc.setFont("helvetica", "normal");
                        doc.text(5, finalY, "\u2022 " + "Di-validasi pada tanggal " + validationDate);
                        finalY = finalY + 5;
                        if (item.status === 'V') {
                          doc.text(5, finalY, "\u2022 " + "Tanggal Pembayaran Refund PPh :" + tglKembali);
                          finalY = finalY + 5;
                        }
                      } else {
                        doc.setFont("helvetica", "bold");
                        doc.text(5, finalY, "Catatan");
                        finalY = finalY + 5;
                        doc.setFont("helvetica", "normal");
                        doc.text(5, finalY, "\u2022 " + "Dicetak pada tanggal " + today + " pukul " + currentHour);
                        finalY = finalY + 5;
                        doc.text(5, finalY, "\u2022 " + "Mohon melampirkan BuPot, fotocopy Kwitansi dan fotocopy Faktur Pajak");
                        finalY = finalY + 5;
                      }
                      // doc.autoTable({ 
                      //     columnStyles: {
                      //       0: {cellWidth:8},
                      //       1: {cellWidth:30, fontStyle: 'bold', fontSize: 9}, 
                      //       2: {halign:'center', cellWidth:20, fontSize: 8},
                      //       3: {cellWidth:20, fontSize: 9,halign:'right'},
                      //       4: {cellWidth:20 , fontSize: 9,halign:'right'},
                      //       5: {halign:'center', cellWidth:30, fontSize: 9,fontStyle: 'bold'},
                      //       6: {halign:'center', fontSize: 8,cellWidth:20},
                      //       7: {halign:'right', cellWidth:20, fontSize: 9},
                      //       8: {halign:'right', cellWidth:20, fontSize: 9},
                      //       9: {halign:'right', cellWidth:20, fontSize: 9},
                      //       10:{halign:'right', cellWidth:20, fontSize: 9},
                      //     }, 
                      //     bodyStyles : {lineColor: [0, 0 ,0 ]},
                      //     headerStyles: {
                      //         lineWidth: 0.5,
                      //         lineColor: [0, 0, 0],
                      //         fillColor: [255, 255, 255],
                      //         textColor:'black'
                      //     },
                      //     footStyles: {
                      //         lineWidth: 0.5,
                      //         lineColor: [0, 0, 0],
                      //         fillColor: [255, 255, 255],
                      //         textColor:'black'
                      //     },                 
                      //     theme: 'grid',
                      //     head: [[
                      //       {content: 'No ',rowSpan:2,styles: {halign: 'center',valign:'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'Bupot ', colSpan: 4,styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'Kwitansi', colSpan: 5, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}}
                      //     ],[
                      //       {content: 'Nomor', colSpan: 1,styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'Tanggal', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'DPP', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'PPH', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'Nomor', colSpan: 1,styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'Tanggal', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'DPP', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'PPN', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: 'PPH', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}}
                      //     ],
                      //     ],
                      //     body: body,
                      //     margin: {top: 90, left: 1, right: 1},
                      //     foot: [[
                      //       {content: 'Grand total Bupot', colSpan: 3, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: sumTotalDppRefund, colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
                      //       {content: sumTotalPPhRefund, colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
                      //       {content: 'Grand total Kwt', colSpan: 2, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                      //       {content: sum, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
                      //       {content: sumb, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
                      //       {content: sumc, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}}
                      //     ]]
                      //   })
                      if (item.status !== 'T' && item.status !== 'V') {
                        //   doc.autoTable({
                        //   bodyStyles : {lineColor: [0, 0 ,0 ]},
                        //   headerStyles: {
                        //       lineWidth: 0.5,
                        //       lineColor: [0, 0, 0]
                        //   },             
                        //   theme : 'grid',
                        //   head: [[{content: 'Validation Notes', colSpan: 2, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}}]],
                        //   body: [
                        //     ['Tgl. Validasi Refund:  ', validationDate],
                        //     ['Tgl. Cetak  Refund:  ', today],
                        //     ['Tgl. Kembali:  ', tglKembali],
                        //     ['Paraf Petugas Validasi:  ', '                          '],
                        //     ['Nama Petugas:  ', '                          '],
                        //   ],
                        //   margin: {left: 135},
                        //   startY: 215,
                        //   lineColor: [0,0,0]
                        // })
                        if (item.status === 'T' || item.status === 'V') {
                          // doc.text("TANDA TERIMA BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                          doc.autoTable({
                            bodyStyles: { lineColor: [0, 0, 0] },
                            headerStyles: {
                              lineWidth: 0,
                              lineColor: [0, 0, 0]
                            },
                            theme: 'grid',
                            head: [[{ content: 'Catatan', colSpan: 2, styles: { halign: 'Left', fillColor: [255, 255, 255], textColor: 'black' } }]],
                            body: [
                              ['Tgl. Validasi Refund:  ', validationDate],
                              ['Tgl. Pembayaran Refund:  ', '                          '],
                              ['Paraf Petugas Validasi:  ', '                          '],
                              ['Nama Petugas:  ', '                          '],
                            ],
                            margin: { left: 135 },
                            startY: finalY,
                            lineColor: [0, 0, 0]
                          })
                        } else {
                          if (item.status_refund === 'Input Refund') {
                            doc.autoTable({
                              bodyStyles: { lineColor: [0, 0, 0] },
                              headerStyles: {
                                lineWidth: 0,
                                lineColor: [0, 0, 0]
                              },
                              theme: 'grid',
                              head: [[{ content: 'Catatan', colSpan: 2, styles: { halign: 'Left', fillColor: [255, 255, 255], textColor: 'black' } }]],
                              body: [
                                ['Tgl. Validasi Refund:  ', validationDate],
                                ['Tgl. Pembayaran Refund:  ', '                          '],
                                ['Paraf Petugas Validasi:  ', '                          '],
                                ['Nama Petugas:  ', '                          '],
                              ],
                              margin: { left: 135 },
                              startY: finalY,
                              lineColor: [0, 0, 0]
                            })
                          } else {
                            doc.autoTable({
                              bodyStyles: { lineColor: [0, 0, 0] },
                              headerStyles: {
                                lineWidth: 0,
                                lineColor: [0, 0, 0]
                              },
                              theme: 'grid',
                              head: [[{ content: 'Catatan', colSpan: 2, styles: { halign: 'Left', fillColor: [255, 255, 255], textColor: 'black' } }]],
                              body: [
                                ['Tgl. Validasi Refund:  ', validationDate],
                                ['Paraf Petugas Validasi:  ', '                          '],
                                ['Nama Petugas:  ', '                          '],
                              ],
                              margin: { left: 135 },
                              startY: finalY,
                              lineColor: [0, 0, 0]
                            })
                          }
                        }
                      }
                      if (item.status === 'V') {
                        var totalPages = doc.internal.getNumberOfPages();
                        for (i = 1; i <= totalPages; i++) {
                          doc.setPage(i);
                          doc.saveGraphicsState();
                          doc.setGState(new doc.GState({ opacity: 0.2 }));
                          doc.setFontSize(100)
                          doc.setTextColor(255, 0, 0);
                          doc.text(watermark, 55, 200, null, 45)
                          doc.restoreGraphicsState();
                        }
                      } else if (item.status === 'T') {
                        var totalPages = doc.internal.getNumberOfPages();
                        for (i = 1; i <= totalPages; i++) {
                          doc.setPage(i);
                          doc.saveGraphicsState();
                          doc.setGState(new doc.GState({ opacity: 0.2 }));
                          doc.setFontSize(100)
                          doc.setTextColor(255, 0, 0);
                          doc.text(watermark, 40, 250, null, 45)
                          doc.restoreGraphicsState();
                        }
                      } else if (item.status === 'R') {
                        var totalPages = doc.internal.getNumberOfPages();
                        for (i = 1; i <= totalPages; i++) {
                          doc.setPage(i);
                          doc.saveGraphicsState();
                          doc.setGState(new doc.GState({ opacity: 0.2 }));
                          doc.setFontSize(100)
                          doc.setTextColor(255, 0, 0);
                          doc.text(watermark, 55, 200, null, 45)
                          doc.restoreGraphicsState();
                        }
                      } else if (item.status === 'C') {
                        var totalPages = doc.internal.getNumberOfPages();
                        for (i = 1; i <= totalPages; i++) {
                          doc.setPage(i);
                          doc.saveGraphicsState();
                          doc.setGState(new doc.GState({ opacity: 0.2 }));
                          doc.setFontSize(100)
                          doc.setTextColor(255, 0, 0);
                          doc.text(watermark, 55, 200, null, 45)
                          doc.restoreGraphicsState();
                        }
                      }
                      // var totalPages = doc.internal.getNumberOfPages();
                      // for (i = 1; i <= totalPages; i++) {
                      //   doc.setPage(i);
                      //   doc.saveGraphicsState();
                      //   doc.setGState(new doc.GState({opacity: 0.2}));
                      //   doc.setFontSize(100)
                      //   doc.setTextColor(255, 0, 0);
                      //   doc.text(watermark, 55, 200, null, 45)
                      //   doc.restoreGraphicsState();  
                      // }
                      // doc.saveGraphicsState();
                      // doc.setGState(new doc.GState({opacity: 0.2}));
                      // doc.setFontSize(100)
                      // doc.setTextColor(255, 0, 0);
                      // doc.text(watermark, 55, 200, null, 45)
                      // doc.restoreGraphicsState();              
                      doc.setProperties({
                        title: "Laporan Bukti Potong"
                      });
                      this.overlay = false;
                      window.open(doc.output('bloburl'))
                    });
                  });
                });

              })
              .catch(error => {
                console.log(error.response)
              })
          })
          .catch(error => {
            console.log(error.response)
          })
      });
      this.dokumenKwtarray2.splice(0);
    },
    // diskon 04 report
    // showPdf(item) {
    //   /* eslint-disable */
    //   this.overlay = true;
    //   var today = new Date();
    //   const formattedDate = today.toLocaleDateString('en-GB', {
    //     day: 'numeric', month: 'short', year: 'numeric'
    //   }).replace(/ /g, '-');
    //   var dd = String(today.getDate()).padStart(2, '0');
    //   var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    //   var yyyy = today.getFullYear();

    //   var seconds = today.getSeconds();
    //   var minutes = today.getMinutes();
    //   var hour = today.getHours();
    //   today = formattedDate;
    //   var currentHour = hour + ':' + minutes + ':' + seconds;
    //   JsBarcode("#barcode3", item.refund_number);
    //   this.dokumenKwtarray3.splice(0);
    //   var sum = 0;
    //   var sumb = 0;
    //   var sumc = 0;
    //   var sumd = 0;
    //   var i = 1;
    //   var sumTotalDppRefund = 0;
    //   var sumTotalPPhRefund = 0;
    //   let uri4 = `dtltrxpage/${item.refund_id}`;
    //   axios.get(uri4).then(response => {
    //     this.dokumenBupot = response.data.data;
    //     this.custid2 = this.dokumenBupot;
    //     axios({
    //       method: 'post',
    //       url: 'getUserIdbyCustId',
    //       data: {
    //         customer_id: this.custid2
    //       },
    //     })
    //       .then(response => {
    //         // window.location.reload();
    //         this.varUserId = response.data.data;
    //         axios({
    //           method: 'post',
    //           url: 'getSuppSiteNameByUserId',
    //           data: {
    //             user_id: this.varUserId
    //           },
    //         })
    //           .then((response) => {
    //             this.supplier_site_name = response.data.data;
    //             //  alert(response.data.data);
    //             this.bank = response.data.data;
    //             let uri5 = `dtlKwtpage/${item.refund_id}/${this.custid2}`;
    //             axios.get(uri5).then(response => {
    //               this.dokumenKwt = response.data.data;
    //               let uri = `getcustnumberandname/${this.custid2}`;
    //               axios.get(uri).then(response => {
    //                 this.customer = response.data.data;
    //                 let uri2 = `getkwtarray/${item.refund_id}/${this.custid2}`;
    //                 axios.get(uri2).then(response => {
    //                   this.dokumenKwtarray = response.data.data;
    //                   // console.log(this.dokumenKwtarray);
    //                   try {
    //                     this.dokumenKwtarray2 = this.dokumenKwtarray.map(this.getKwtValue)
    //                   }
    //                   catch (err) {
    //                     console.log(err);
    //                   }
    //                   this.data = this.dokumenKwtarray
    //                   this.data.forEach(item => {
    //                     this.dokumenKwtarray3.push({
    //                       bupot_number: item.bupot_number,
    //                       bupot_date: item.bupot_date,
    //                       dpp_amount_bupot: this.formatCurrency2(item.dpp_amount_bupot),
    //                       pph_amount_bupot: this.formatCurrency2(item.pph_amount_bupot),
    //                       kwt_id: item.kwt_id,
    //                       kwt_number: item.kwt_number,
    //                       kwt_date: item.kwt_date,
    //                       dpp_amount: this.formatCurrency2(item.dpp_amount),
    //                       ppn_amount: this.formatCurrency2(item.ppn_amount),
    //                       pph_amount: this.formatCurrency2(item.pph_amount2),
    //                       tarif: item.tarif,
    //                       dpp_disc_fee: this.formatCurrency2(item.dpp_disc_fee)
    //                     })
    //                   })
    //                   // console.log(this.dokumenKwtarray3);
    //                   this.dokumenKwtarray.forEach(item => {
    //                     sum = sum + parseInt(item.dpp_amount);
    //                   })
    //                   this.dokumenKwtarray.forEach(item => {
    //                     sumb = sumb + parseInt(item.ppn_amount);
    //                   })
    //                   this.dokumenKwtarray.forEach(item => {
    //                     sumc = sumc + parseInt(item.pph_amount2);
    //                   })
    //                   this.dokumenKwtarray.forEach(item => {
    //                     sumd = sumd + parseInt(item.dpp_disc_fee);
    //                   })
    //                   sum = this.formatCurrency2(sum);
    //                   sumb = this.formatCurrency2(sumb);
    //                   sumc = this.formatCurrency2(sumc);
    //                   sumd = this.formatCurrency2(sumd);
    //                   sumTotalDppRefund = this.formatCurrency2(item.dpp_amount);
    //                   sumTotalPPhRefund = this.formatCurrency2(item.pph_amount);
    //                   try {
    //                     this.dokumenKwtarray3 = this.dokumenKwtarray3.map(this.getKwtValue)
    //                   }
    //                   catch (err) {
    //                     console.log(err);
    //                   }
    //                   var raw = this.dokumenKwtarray3
    //                   // console.log(raw);
    //                   var body = []
    //                   var bupotIdonly = []
    //                   var newIdbupotonly = []
    //                   var index = 1;
    //                   var count = 0;
    //                   var counts = [];
    //                   var indikator = 1;
    //                   var activeBupot = "";
    //                   raw.forEach(item => [
    //                     bupotIdonly.push({
    //                       bupotNumber: item[0]
    //                     })
    //                   ])
    //                   newIdbupotonly = bupotIdonly.map(this.getBupotNumber);
    //                   newIdbupotonly.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
    //                   var j = 0;
    //                   for (i = 0; i < raw.length; i++) {
    //                     var row = []
    //                     var nextRow = []
    //                     for (var key in raw[i]) {
    //                       row.push(raw[i][key])
    //                     }
    //                     // console.log("sebelum");
    //                     // console.log(row);
    //                     var no_bupot = row[0];
    //                     var tanggal_bupot = row[1];
    //                     var total_dpp_bupot = row[6];
    //                     var total_dpp_disc_fee = row[7];
    //                     var total_pph_bupot = row[9];
    //                     var no_kwitansi = row[4];
    //                     var tgl_kwitansi = row[5];
    //                     var tarif = row[10];
    //                     row.shift();
    //                     row.shift();
    //                     row.shift();
    //                     row.shift();
    //                     row.shift();
    //                     row.shift();
    //                     // console.log(row);
    //                     row.unshift({
    //                       content: tgl_kwitansi,
    //                       styles: { valign: 'middle', halign: 'center' },
    //                     })
    //                     row.unshift({
    //                       content: no_kwitansi,
    //                       styles: { valign: 'middle', halign: 'center' },
    //                     })
    //                     row.unshift({
    //                       content: index,
    //                       styles: { valign: 'middle', halign: 'center' },
    //                     })
    //                     // console.log(row);
    //                     row.push({
    //                       content: no_bupot,
    //                       styles: { valign: 'middle', halign: 'center' },
    //                     })
    //                     row.push({
    //                       content: tarif,
    //                       styles: { valign: 'middle', halign: 'center' },
    //                     })
    //                     row.push({
    //                       content: total_dpp_bupot,
    //                       styles: { valign: 'middle', halign: 'right' },
    //                     })
    //                     row.push({
    //                       content: total_dpp_disc_fee,
    //                       styles: { valign: 'middle', halign: 'right' },
    //                     })
    //                     row.push({
    //                       content: total_pph_bupot,
    //                       styles: { valign: 'middle', halign: 'right' },
    //                     })
    //                     row.push({
    //                       content: tanggal_bupot,
    //                       styles: { valign: 'middle', halign: 'center' },
    //                     })
    //                     // console.log(row);
    //                     row.splice(3, 5);
    //                     // console.log(row);
    //                     index = index + 1;
    //                     body.push(row);
    //                     // console.log("body");
    //                     // console.log(body);
    //                   }
    //                   // for (i = 0; i < raw.length; i++) {
    //                   //   console.log("data ke",i)
    //                   //   var row = []
    //                   //   var nextRow = []
    //                   //   for (var key in raw[i]) {
    //                   //     row.push(raw[i][key])
    //                   //   }
    //                   //   for (var key2 in raw[i+1]){
    //                   //     nextRow.push(raw[i+1][key2])
    //                   //   }
    //                   //   if(activeBupot != row[0]) {
    //                   //     indikator = 1;
    //                   //   } 
    //                   //   if (indikator === 1){
    //                   //     activeBupot = row[0];
    //                   //     indikator = 0;
    //                   //     count = counts[activeBupot];
    //                   //     j=0;
    //                   //   }
    //                   //   if (j % count === 0){
    //                   //     var no_bupot = row[0];
    //                   //     var tanggal_bupot = row[1];
    //                   //     var total_dpp_bupot = row[2];
    //                   //     var total_pph_bupot = row[3];              
    //                   //     row.shift();
    //                   //     row.shift();
    //                   //     row.shift();
    //                   //     row.shift();
    //                   //     row.unshift({
    //                   //       rowSpan: count,
    //                   //       content: total_pph_bupot,
    //                   //       styles: { valign: 'middle', halign: 'center' },
    //                   //     })
    //                   //     row.unshift({
    //                   //       rowSpan: count,
    //                   //       content: total_dpp_bupot,
    //                   //       styles: { valign: 'middle', halign: 'center' },
    //                   //     })
    //                   //     row.unshift({
    //                   //       rowSpan: count,
    //                   //       content: tanggal_bupot,
    //                   //       styles: { valign: 'middle', halign: 'center' },
    //                   //     })
    //                   //     row.unshift({
    //                   //       rowSpan: count,
    //                   //       content: no_bupot,
    //                   //       styles: { valign: 'middle', halign: 'center' },
    //                   //     })
    //                   //     row.unshift({
    //                   //       rowSpan: count,
    //                   //       content: index,
    //                   //       styles: { valign: 'middle', halign: 'center' },
    //                   //     })
    //                   //     j=j+1;
    //                   //     index = index + 1;
    //                   //   }
    //                   //   else{
    //                   //     row.shift();
    //                   //     row.shift();
    //                   //     row.shift();
    //                   //     row.shift();
    //                   //     j=j+1
    //                   //   }
    //                   //   body.push(row);
    //                   //   console.log(body)
    //                   // }
    //                   const img = document.querySelector('img#barcode3');
    //                   const logo = require('@/assets/indogrosir.png')
    //                   var imgLogo = new Image()
    //                   imgLogo.src = logo
    //                   // var img3 = new Image();
    //                   // img3.url = '../../../assets/indogrosir.png';
    //                   var cust_name = this.customer.map(({ customer_name }) => customer_name)
    //                   var cust_number = this.customer.map(({ customer_number }) => customer_number)
    //                   var header = cust_name;
    //                   var tglKembali = this.formatDate2(item.bupot_giro_date);
    //                   if (item.bupot_giro_date == null) {
    //                     tglKembali = null;
    //                   }
    //                   var validationDate = this.formatDate2(item.validation_date);
    //                   if (item.validation_date == null) {
    //                     validationDate = null;
    //                   }
    //                   // doc.text(header, 13, 5, { baseline: 'middle' });
    //                   var doc = new jsPDF();
    //                   // doc.addImage("../../../assets/indogrosir.png", 'JPEG', 55, 25,100,25);
    //                   doc.setFont("helvetica", "bold");
    //                   doc.getFontList();
    //                   if (item.status === 'T' || item.status === 'V') {
    //                     // doc.text("TANDA TERIMA BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
    //                     if (item.status_refund === 'Input Refund') {
    //                       doc.text("TANDA TERIMA REFUND BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
    //                     } else {
    //                       doc.text("TANDA TERIMA PENYERAHAN BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
    //                     }
    //                   } else {
    //                     if (item.status_refund === 'Input Refund') {
    //                       doc.text("TANDA TERIMA REFUND BUKTI POTONG SEMENTARA", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
    //                     } else {
    //                       doc.text("TANDA TERIMA PENYERAHAN BUKTI POTONG SEMENTARA", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
    //                     }
    //                   }
    //                   doc.setFont("helvetica", "normal");
    //                   doc.setFontSize(10);
    //                   doc.addImage(img.src, 'JPEG', 80, 40, 50, 25);
    //                   doc.addImage(imgLogo, 'PNG', 5, 5, 50, 20)
    //                   doc.text("Nomor TT : " + item.refund_number + ' / ' + item.status_pph, 80, 38, { baseline: 'middle', align: 'left', lineHeightFactor: '0.5' });
    //                   doc.setFontSize(7);
    //                   doc.text("Supplier", 140, 7, { align: 'left', lineHeightFactor: '0.5' });
    //                   doc.setFontSize(7);
    //                   doc.text(header, 140, 12, { align: 'left', lineHeightFactor: '0.5' });
    //                   doc.text(this.supplier_site_name, 140, 17, { align: 'left', lineHeightFactor: '0.5' });
    //                   doc.setFontSize(9);
    //                   doc.autoTable({
    //                     columnStyles: {
    //                       0: { cellWidth: 10 },
    //                       1: { cellWidth: 34 },
    //                       2: { cellWidth: 25 },
    //                       3: { cellWidth: 25 },
    //                       4: { cellWidth: 16 },
    //                       5: { cellWidth: 20 },
    //                       6: { cellWidth: 20 },
    //                       7: { cellWidth: 20 },
    //                       8: { cellWidth: 25 }
    //                     },
    //                     headStyles: {
    //                       lineWidth: 0.5,
    //                       lineColor: [0, 0, 0],
    //                       fillColor: [255, 255, 255],
    //                       textColor: 'black'
    //                     },
    //                     theme: 'grid',
    //                     head: [[
    //                       { content: 'No ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                       { content: 'Nomor Invoice / Kwitansi ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                       { content: 'Tgl Kwitansi ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                       { content: 'Nomor Bupot ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                       { content: 'Tarif PPH(%) ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                       { content: 'Nilai (Rp.) ', colSpan: 3, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                       { content: 'Tgl BuPot ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                     ], [
    //                       { content: 'DPP', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                       { content: 'DPP dist. fee', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                       { content: 'PPH', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                     ]],
    //                     bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
    //                     startY: 70,
    //                     margin: { top: 5, left: 5, right: 2 },
    //                     body: body,
    //                     footStyles: {
    //                       lineWidth: 0.5,
    //                       lineColor: [0, 0, 0],
    //                       fillColor: [255, 255, 255],
    //                       textColor: 'black'
    //                     },
    //                     rowPageBreak: 'auto'
    //                   })
    //                   let finalY = doc.lastAutoTable.finalY;
    //                   doc.setFont("helvetica", "bold");
    //                   doc.autoTable({
    //                     columnStyles: {
    //                       0: { cellWidth: 110 },
    //                       1: { cellWidth: 20 },
    //                       2: { cellWidth: 20 },
    //                       3: { cellWidth: 20 },
    //                       4: { cellWidth: 25 },
    //                     },
    //                     bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
    //                     body: [[
    //                       { content: 'TOTAL', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                       { content: sum, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
    //                       { content: sumd, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
    //                       { content: sumc, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
    //                       { content: '', colSpan: 1, styles: { halign: 'right', fillColor: [224, 224, 235], textColor: 'black', fontSize: 9 } }
    //                     ]],
    //                     margin: { top: 5, left: 5, right: 2 },
    //                     startY: finalY,
    //                     lineColor: [0, 0, 0]
    //                   })
    //                   finalY = doc.lastAutoTable.finalY;
    //                   finalY = finalY + 10; // The y position on the page
    //                   if (item.status === 'T' || item.status === 'V') {
    //                     doc.setFont("helvetica", "bold");
    //                     doc.text(5, finalY, "Catatan");
    //                     finalY = finalY + 5;
    //                     doc.setFont("helvetica", "normal");
    //                     doc.text(5, finalY, "\u2022 " + "Di-validasi pada tanggal " + validationDate);
    //                     finalY = finalY + 5;
    //                     if (item.status === 'V') {
    //                       doc.text(5, finalY, "\u2022 " + "Tanggal Pembayaran Refund PPh :" + tglKembali);
    //                       finalY = finalY + 5;
    //                     }
    //                   } else {
    //                     doc.setFont("helvetica", "bold");
    //                     doc.text(5, finalY, "Catatan");
    //                     finalY = finalY + 5;
    //                     doc.setFont("helvetica", "normal");
    //                     doc.text(5, finalY, "\u2022 " + "Dicetak pada tanggal " + today + " pukul " + currentHour);
    //                     finalY = finalY + 5;
    //                     doc.text(5, finalY, "\u2022 " + "Mohon melampirkan BuPot, fotocopy Kwitansi dan fotocopy Faktur Pajak");
    //                     finalY = finalY + 5;
    //                   }
    //                   // doc.autoTable({ 
    //                   //     columnStyles: {
    //                   //       0: {cellWidth:8},
    //                   //       1: {cellWidth:30, fontStyle: 'bold', fontSize: 9}, 
    //                   //       2: {halign:'center', cellWidth:20, fontSize: 8},
    //                   //       3: {cellWidth:20, fontSize: 9,halign:'right'},
    //                   //       4: {cellWidth:20 , fontSize: 9,halign:'right'},
    //                   //       5: {halign:'center', cellWidth:30, fontSize: 9,fontStyle: 'bold'},
    //                   //       6: {halign:'center', fontSize: 8,cellWidth:20},
    //                   //       7: {halign:'right', cellWidth:20, fontSize: 9},
    //                   //       8: {halign:'right', cellWidth:20, fontSize: 9},
    //                   //       9: {halign:'right', cellWidth:20, fontSize: 9},
    //                   //       10:{halign:'right', cellWidth:20, fontSize: 9},
    //                   //     }, 
    //                   //     bodyStyles : {lineColor: [0, 0 ,0 ]},
    //                   //     headerStyles: {
    //                   //         lineWidth: 0.5,
    //                   //         lineColor: [0, 0, 0],
    //                   //         fillColor: [255, 255, 255],
    //                   //         textColor:'black'
    //                   //     },
    //                   //     footStyles: {
    //                   //         lineWidth: 0.5,
    //                   //         lineColor: [0, 0, 0],
    //                   //         fillColor: [255, 255, 255],
    //                   //         textColor:'black'
    //                   //     },                 
    //                   //     theme: 'grid',
    //                   //     head: [[
    //                   //       {content: 'No ',rowSpan:2,styles: {halign: 'center',valign:'center', fillColor: [255, 255, 255], textColor:'black'}},
    //                   //       {content: 'Bupot ', colSpan: 4,styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
    //                   //       {content: 'Kwitansi', colSpan: 5, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}}
    //                   //     ],[
    //                   //       {content: 'Nomor', colSpan: 1,styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
    //                   //       {content: 'Tanggal', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
    //                   //       {content: 'DPP', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
    //                   //       {content: 'PPH', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
    //                   //       {content: 'Nomor', colSpan: 1,styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
    //                   //       {content: 'Tanggal', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
    //                   //       {content: 'DPP', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
    //                   //       {content: 'PPN', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
    //                   //       {content: 'PPH', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}}
    //                   //     ],
    //                   //     ],
    //                   //     body: body,
    //                   //     margin: {top: 90, left: 1, right: 1},
    //                   //     foot: [[
    //                   //       {content: 'Grand total Bupot', colSpan: 3, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
    //                   //       {content: sumTotalDppRefund, colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
    //                   //       {content: sumTotalPPhRefund, colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
    //                   //       {content: 'Grand total Kwt', colSpan: 2, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
    //                   //       {content: sum, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
    //                   //       {content: sumb, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
    //                   //       {content: sumc, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}}
    //                   //     ]]
    //                   //   })
    //                   if (item.status !== 'T' && item.status !== 'V') {
    //                     //   doc.autoTable({
    //                     //   bodyStyles : {lineColor: [0, 0 ,0 ]},
    //                     //   headerStyles: {
    //                     //       lineWidth: 0.5,
    //                     //       lineColor: [0, 0, 0]
    //                     //   },             
    //                     //   theme : 'grid',
    //                     //   head: [[{content: 'Validation Notes', colSpan: 2, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}}]],
    //                     //   body: [
    //                     //     ['Tgl. Validasi Refund:  ', validationDate],
    //                     //     ['Tgl. Cetak  Refund:  ', today],
    //                     //     ['Tgl. Kembali:  ', tglKembali],
    //                     //     ['Paraf Petugas Validasi:  ', '                          '],
    //                     //     ['Nama Petugas:  ', '                          '],
    //                     //   ],
    //                     //   margin: {left: 135},
    //                     //   startY: 215,
    //                     //   lineColor: [0,0,0]
    //                     // })
    //                     if (item.status === 'T' || item.status === 'V') {
    //                       // doc.text("TANDA TERIMA BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
    //                       doc.autoTable({
    //                         bodyStyles: { lineColor: [0, 0, 0] },
    //                         headerStyles: {
    //                           lineWidth: 0,
    //                           lineColor: [0, 0, 0]
    //                         },
    //                         theme: 'grid',
    //                         head: [[{ content: 'Catatan', colSpan: 2, styles: { halign: 'Left', fillColor: [255, 255, 255], textColor: 'black' } }]],
    //                         body: [
    //                           ['Tgl. Validasi Refund:  ', validationDate],
    //                           ['Tgl. Pembayaran Refund:  ', '                          '],
    //                           ['Paraf Petugas Validasi:  ', '                          '],
    //                           ['Nama Petugas:  ', '                          '],
    //                         ],
    //                         margin: { left: 135 },
    //                         startY: finalY,
    //                         lineColor: [0, 0, 0]
    //                       })
    //                     } else {
    //                       if (item.status_refund === 'Input Refund') {
    //                         doc.autoTable({
    //                           bodyStyles: { lineColor: [0, 0, 0] },
    //                           headerStyles: {
    //                             lineWidth: 0,
    //                             lineColor: [0, 0, 0]
    //                           },
    //                           theme: 'grid',
    //                           head: [[{ content: 'Catatan', colSpan: 2, styles: { halign: 'Left', fillColor: [255, 255, 255], textColor: 'black' } }]],
    //                           body: [
    //                             ['Tgl. Validasi Refund:  ', validationDate],
    //                             ['Tgl. Pembayaran Refund:  ', '                          '],
    //                             ['Paraf Petugas Validasi:  ', '                          '],
    //                             ['Nama Petugas:  ', '                          '],
    //                           ],
    //                           margin: { left: 135 },
    //                           startY: finalY,
    //                           lineColor: [0, 0, 0]
    //                         })
    //                       } else {
    //                         doc.autoTable({
    //                           bodyStyles: { lineColor: [0, 0, 0] },
    //                           headerStyles: {
    //                             lineWidth: 0,
    //                             lineColor: [0, 0, 0]
    //                           },
    //                           theme: 'grid',
    //                           head: [[{ content: 'Catatan', colSpan: 2, styles: { halign: 'Left', fillColor: [255, 255, 255], textColor: 'black' } }]],
    //                           body: [
    //                             ['Tgl. Validasi Refund:  ', validationDate],
    //                             ['Paraf Petugas Validasi:  ', '                          '],
    //                             ['Nama Petugas:  ', '                          '],
    //                           ],
    //                           margin: { left: 135 },
    //                           startY: finalY,
    //                           lineColor: [0, 0, 0]
    //                         })
    //                       }
    //                     }
    //                   }
    //                   if (item.status === 'V') {
    //                     var totalPages = doc.internal.getNumberOfPages();
    //                     for (i = 1; i <= totalPages; i++) {
    //                       doc.setPage(i);
    //                       doc.saveGraphicsState();
    //                       doc.setGState(new doc.GState({ opacity: 0.2 }));
    //                       doc.setFontSize(100)
    //                       doc.setTextColor(255, 0, 0);
    //                       doc.text(watermark, 55, 200, null, 45)
    //                       doc.restoreGraphicsState();
    //                     }
    //                   } else if (item.status === 'T') {
    //                     var totalPages = doc.internal.getNumberOfPages();
    //                     for (i = 1; i <= totalPages; i++) {
    //                       doc.setPage(i);
    //                       doc.saveGraphicsState();
    //                       doc.setGState(new doc.GState({ opacity: 0.2 }));
    //                       doc.setFontSize(100)
    //                       doc.setTextColor(255, 0, 0);
    //                       doc.text(watermark, 40, 250, null, 45)
    //                       doc.restoreGraphicsState();
    //                     }
    //                   } else if (item.status === 'R') {
    //                     var totalPages = doc.internal.getNumberOfPages();
    //                     for (i = 1; i <= totalPages; i++) {
    //                       doc.setPage(i);
    //                       doc.saveGraphicsState();
    //                       doc.setGState(new doc.GState({ opacity: 0.2 }));
    //                       doc.setFontSize(100)
    //                       doc.setTextColor(255, 0, 0);
    //                       doc.text(watermark, 55, 200, null, 45)
    //                       doc.restoreGraphicsState();
    //                     }
    //                   } else if (item.status === 'C') {
    //                     var totalPages = doc.internal.getNumberOfPages();
    //                     for (i = 1; i <= totalPages; i++) {
    //                       doc.setPage(i);
    //                       doc.saveGraphicsState();
    //                       doc.setGState(new doc.GState({ opacity: 0.2 }));
    //                       doc.setFontSize(100)
    //                       doc.setTextColor(255, 0, 0);
    //                       doc.text(watermark, 55, 200, null, 45)
    //                       doc.restoreGraphicsState();
    //                     }
    //                   }
    //                   // var totalPages = doc.internal.getNumberOfPages();
    //                   // for (i = 1; i <= totalPages; i++) {
    //                   //   doc.setPage(i);
    //                   //   doc.saveGraphicsState();
    //                   //   doc.setGState(new doc.GState({opacity: 0.2}));
    //                   //   doc.setFontSize(100)
    //                   //   doc.setTextColor(255, 0, 0);
    //                   //   doc.text(watermark, 55, 200, null, 45)
    //                   //   doc.restoreGraphicsState();  
    //                   // }
    //                   // doc.saveGraphicsState();
    //                   // doc.setGState(new doc.GState({opacity: 0.2}));
    //                   // doc.setFontSize(100)
    //                   // doc.setTextColor(255, 0, 0);
    //                   // doc.text(watermark, 55, 200, null, 45)
    //                   // doc.restoreGraphicsState();              
    //                   doc.setProperties({
    //                     title: "Laporan Bukti Potong"
    //                   });
    //                   this.overlay = false;
    //                   window.open(doc.output('bloburl'))
    //                 });
    //               });
    //             });

    //           })
    //           .catch(error => {
    //             console.log(error.response)
    //           })
    //       })
    //       .catch(error => {
    //         console.log(error.response)
    //       })
    //   });
    //   this.dokumenKwtarray2.splice(0);
    // },
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'IDR',
      });

      return formatter.format(value); /* $2,500.00 */
    },
    triggerSwitch() {
      // alert(this.switch1);
      this.alasanEdit = [];
      if (this.switch1 === true) {
        this.disabledPilihAlasan = true;
        this.disableIsiAlasan = false;
      } else {
        this.disabledPilihAlasan = false;
        this.disableIsiAlasan = true;
      }
    },
    formatCurrency2(value) {
      var formatter = new Intl.NumberFormat('en-US', {
      });

      return formatter.format(value); /* $2,500.00 */
    },
    formatDate(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    formatDate2(value) {
      return moment(value).format("DD-MMM-YYYY");
    },
    // getKwtValue(item) {
    //   var data = [item.bupot_number, item.bupot_date, item.dpp_amount_bupot, item.pph_amount_bupot, item.kwt_number, item.kwt_date, item.dpp_amount, item.dpp_disc_fee, item.ppn_amount, item.pph_amount, item.tarif];
    //   return data;
    // },

    getKwtValue(item) {
      var data = [item.bupot_number, item.bupot_date, item.dpp_amount_bupot, item.pph_amount_bupot, item.kwt_number, item.kwt_date, item.dpp_amount, item.ppn_amount, item.pph_amount, item.tarif];
      return data;
    },
    getBupotNumber(item) {
      var data = [item.bupotNumber];
      return data;
    },
  },
  components: {
    DatePicker
  }
}
</script>